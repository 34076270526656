import React from 'react'
import ReactTooltip from 'react-tooltip'
import _ from 'lodash'
import styles from './pricing.module.scss'
import icon from '../img/icons/accept.svg'

export default function PricingList({ pricingList }) {
    const iconInfo = (
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="0004.-Pricing-#02" transform="translate(-1585.000000, -1113.000000)" fill="#C1C8D1" fill-rule="nonzero">
                    <g id="Group-18" transform="translate(264.000000, 557.000000)">
                        <g id="Group-17" transform="translate(1076.000000, 0.000000)">
                            <g id="Group-11">
                                <g id="Group-12">
                                    <g id="Pricing-advantage-Copy-41" transform="translate(0.000000, 540.000000)">
                                        <g id="Group" transform="translate(16.000000, 9.000000)">
                                            <g id="icon" transform="translate(229.000000, 7.000000)">
                                                <path d="M8,1.33333333 C11.6818983,1.33333333 14.6666667,4.31810167 14.6666667,8 C14.6666667,11.6818983 11.6818983,14.6666667 8,14.6666667 C6.23189007,14.6666667 4.53619732,13.9642877 3.28595479,12.7140452 C2.03571227,11.4638027 1.33333333,9.76810993 1.33333333,8 C1.33333333,4.31810167 4.31810167,1.33333333 8,1.33333333 Z M8,6.66666667 C7.63181017,6.66666667 7.33333333,6.9651435 7.33333333,7.33333333 L7.33333333,7.33333333 L7.33333333,10.6666667 L7.33781849,10.7444141 C7.37632856,11.0759732 7.65810944,11.3333333 8,11.3333333 C8.36818983,11.3333333 8.66666667,11.0348565 8.66666667,10.6666667 L8.66666667,10.6666667 L8.66666667,7.33333333 L8.66218151,7.25558592 C8.62367144,6.92402679 8.34189056,6.66666667 8,6.66666667 Z M8,4.66666667 C7.63181017,4.66666667 7.33333333,4.9651435 7.33333333,5.33333333 C7.33333333,5.70152317 7.63181017,6 8,6 C8.36818983,6 8.66666667,5.70152317 8.66666667,5.33333333 C8.66666667,4.9651435 8.36818983,4.66666667 8,4.66666667 Z" id="Shape"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );

    return (
        <>
            {
                !_.isEmpty(pricingList) && (
                    pricingList.map(el => <div className={styles.listItem}>
                        {el.tip !== '' &&
                            <ReactTooltip
                                className='tooltip-pricing'
                                place="top"
                                type="dark"
                                effect="solid"
                            />}
                        <img src={icon} width="auto" height="auto" alt="icon" />
                        <span>{el.text}</span>
                        {el.tip !== '' && <div data-html="true" data-tip={el.tip}>{iconInfo}</div>}
                    </div>)
                )
            }
        </>
    )
}
