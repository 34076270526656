import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from '../components/HeaderWhite/header'
import Footer from '../components/Footer/footer'
import Pricing from '../components/CookiePolicy/pricing'
import { Helmet } from "react-helmet";

import pricing from '../components/img/pricing.png'

// const content = `DueFocus time tracking software is 100% FREE in Beta. Take your time tracking to the next level with DueFocus`
const content = `Duefocus is a free time tracking software that enables tracking time with timesheets and sends advanced reports`

const PricingPage = () => (
  <>
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>Pricing - duefocus</title>
      <meta name="description" content={content} />
      <meta property="og:image" content={pricing} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Pricing - duefocus" />
      <meta property="og:description" content={content} />
      <meta property="og:url" content="https://www.duefocus.com/" />
      <meta name="twitter:image:src" content={pricing} />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href="https://www.duefocus.com/pricing/" />
    </Helmet>
    <Header />
    <Pricing />
    <Footer />
  </>
)

export default PricingPage