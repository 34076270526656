import React from 'react'
import Switch from "react-switch";
import styles from './pricing.module.scss'
import buttons from '../buttons.module.scss'
import EmptyBlock from '../Header/emptyBlock'
import PricingList from './pricingList'

import line from '../img/icons/line.svg'

import trello from '../img/icons/integPageIcons/allIntegrations/Logo_tracker_trello.svg'
import asana from '../img/icons/integPageIcons/allIntegrations/Logo_tracker_Asana.svg'
import gitgub from '../img/icons/integPageIcons/allIntegrations/Logo_tracker_github.svg'
import liquidplanner from '../img/icons/integPageIcons/allIntegrations/Logo_tracker_liquidplanner.svg'
import jira from '../img/icons/integPageIcons/allIntegrations/Logo_tracker_jira.svg'
import breeze from '../img/icons/integPageIcons/allIntegrations/Logo_tracker_breeze.svg'
import gitlab from '../img/icons/integPageIcons/allIntegrations/Logo_tracker_gitlab.svg'
import collab from '../img/icons/integPageIcons/allIntegrations/ActiveCollab.svg'
import pivotal from '../img/icons/integPageIcons/allIntegrations/Logo_tracker_pivotal.svg'
import redmine from '../img/icons/integPageIcons/allIntegrations/Logo_tracker_redmine.svg'
import vrike from '../img/icons/integPageIcons/allIntegrations/Logo_tracker_wrike.svg'
import basecamp from '../img/icons/integPageIcons/allIntegrations/Logo_tracker_basecamp.svg'



const imgs = [
    trello,
    asana,
    gitgub,
    jira,
    liquidplanner,
    breeze,
    gitlab,
    basecamp,
    collab,
    pivotal,
    redmine,
    vrike,
];
const Free = [
    {
        text: '5 active users',
        tip: ''
    },
    {
        text: '1 active integration ',
        tip: ''
    },
    {
        text: 'Built-in task tracker',
        tip: ''
    },
    {
        text: 'Limited screenshots',
        tip: 'Random automatic screenshots'
    },
    {
        text: 'Apps & URLs tracking',
        tip: ''
    },
    {
        text: 'Productivity analytics',
        tip: 'Track focus and all activities according to categories: </br> work,learning, communication, entertainment, other'
    },
    {
        text: 'Activity points',
        tip: 'Get points based on mouse and keyboard activity'
    },
    {
        text: 'Time audit',
        tip: 'Analyze efficiency of spent time'
    },
    {
        text: 'Multi-platform tracking',
        tip: 'Manage time from Desktop app, </br>Web app or Google Chrome Browser'
    }
];
const Starter = [
    {
        text: 'Unlimited active users ',
        tip: ''
    },
    {
        text: '3 active integrations',
        tip: ''
    },
    {
        text: 'Built-in task tracker',
        tip: ''
    },
    {
        text: 'Unlimited screenshots',
        tip: 'Set unlimited amount of random automatic screenshots'
    },
    {
        text: 'Apps & URLs tracking',
        tip: ''
    },
    {
        text: 'Productivity analytics',
        tip: ''
    },
    {
        text: '5 teams in Team Pulse',
        tip: 'Control up to 5 teams according to the client,</br> department or project'
    },
    {
        text: 'Employee charts',
        tip: 'Monitor all employees efficiency performance</br> based on activities'
    },
    ,
    {
        text: 'Limited invoices',
        tip: 'Generate up to 5 invoices during a month'
    },
    {
        text: 'Activity points',
        tip: ''
    },
    {
        text: 'Multi-platform tracking',
        tip: 'Manage time from Desktop app,</br> Web app or Google Chrome Browser'
    }
];
const Premium = [
    {
        text: 'Unlimited active users ',
        tip: ''
    },
    {
        text: 'Unlimited active integrations',
        tip: ''
    },
    {
        text: 'Built-in task tracker',
        tip: ''
    },
    {
        text: 'Unlimited screenshots',
        tip: ''
    },
    {
        text: 'Apps & URLs tracking',
        tip: ''
    },
    {
        text: 'Productivity analytics',
        tip: ''
    },
    {
        text: 'Unlimited teams in Team Pulse',
        tip: 'Control unlimited amount of teams according</br> to the client, department or project'
    },
    {
        text: 'Employee charts',
        tip: ''
    },
    {
        text: 'Unlimited invoices',
        tip: 'Generate unlimited amount of invoices'
    },
    {
        text: 'Activity points',
        tip: ''
    },
    {
        text: 'Multi-platform tracking',
        tip: 'Manage time from Desktop app,</br> Web app or Google Chrome Browser'
    },
    ,
    {
        text: 'Client reporting',
        tip: 'Download or send reports directly to your clients'
    },
    {
        text: 'Email reports',
        tip: ''
    }
];
const Enterprise = [
    {
        text: 'All Premium Features +',
        tip: ''
    },
    {
        text: 'Schedule email reports',
        tip: 'Set time and send reports to clients automatically'
    },
    {
        text: '24/7 support',
        tip: ''
    },
    {
        text: 'Product Education Plan ',
        tip: ''
    },
    {
        text: 'Dedicated account manager',
        tip: ''
    },
    {
        text: '99.9% uptime SLA',
        tip: ''
    }
];
export default class pricing extends React.Component {
    constructor() {
        super();
        this.state = { checked: false };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(checked) {
        this.setState({ checked }, this.handleChangePrice());
    }
    handleChangePrice = () => {
        const priceStarter = document.getElementById('price-starter');
        const pricePremium = document.getElementById('price-premium');
        const label = document.getElementById('bill-label');
        const yearly = document.getElementById('yearly');
        const monthly = document.getElementById('monthly');
        if (this.state.checked === true) {
            priceStarter.innerHTML = '$9'
            pricePremium.innerHTML = '$12'
            yearly.removeAttribute('class');
            monthly.className += ' active-word';
            label.classList.remove('bill-active')
        } else {
            monthly.classList.remove('active-word');
            yearly.className += ' active-word'
            label.className += ' bill-active'
            priceStarter.innerHTML = '$6,75'
            pricePremium.innerHTML = '$9'
        }
    }
    openIntercom = () => {
        window.Intercom('showNewMessage')
    }
    openSignUp = () => {
        return window.location.href = '/sign-up/';
    }
    render() {
        return (
            <>
                <EmptyBlock />
                <div className={styles.main}>
                    <div className={styles.mainHeader}>
                        <h1>We Are Supporting Remote Companies and Teams</h1>
                        <p>The effects of COVID-19 pandemic have severely impacted companies all over the world. We’re now offering a <span>60-days free</span> trial
                            of a
                            fully functional version of DueFocus for all Pricing Plans to provide
                            some
                            support during this incredibly difficult time.</p>
                    </div>
                    <div className={styles.blockBill}>
                        <div id='bill-label' className={styles.label}>SAVE UP TO 25% WITH YEARLY</div>
                        <svg className={styles.fillGreen} width="22" height="16" viewBox="0 0 22 16">
                            <path d="M2.39,17,1,17l.07-2c11.37.39,17.9-4.17,20-14l2,.42C20.83,11.76,13.9,17,2.39,17Z"
                                transform="translate(-1 -1)"></path>
                        </svg>
                    </div>
                    <div className={styles.switcher}>
                        <span id='monthly' className='active-word'>Bill Monthly</span>
                        <Switch offColor='#A0ABBA' onColor='#60d956' uncheckedIcon={false} checkedIcon={false}
                            handleDiameter={16} onChange={this.handleChange} checked={this.state.checked} />
                        <span id='yearly'>Bill Yearly</span>

                    </div>
                    <div className={styles.cards}>
                        <div className={styles.leftBlock}>
                            <div className={`${styles.block} ${styles.blockOrder}`}>
                                <div className={styles.blockTitle}>Free</div>
                                <div className={styles.blockPrice}>
                                    <span>$0</span>
                                    <span className={styles.text}>mnt / per user</span>
                                    <button className={buttons.learnMore} onClick={this.openSignUp}>Get Free Account</button>
                                </div>
                                <PricingList pricingList={Free} />
                            </div>
                            <div className={styles.block}>
                                <div className={styles.blockTitle}>Starter</div>
                                <div className={`${styles.blockPrice} ${styles.blockPriceBlack}`}>
                                    <img src={line} width="auto" height="auto" alt="line" />
                                    <p>Free</p>
                                    <span id="price-starter" className={styles.textIndex}>$9</span>
                                    <span className={styles.text}>mnt / per user</span>
                                    <button className={buttons.learnMore} onClick={this.openSignUp}>Start Free Trial</button>
                                </div>
                                <PricingList pricingList={Starter} />
                            </div>
                            <div className={`${styles.block} ${styles.blockBlueBorder}`}>
                                <div className={`${styles.blockTitle} ${styles.blockTitleBorder}`}>Premium</div>
                                <div className={`${styles.blockPrice} ${styles.blockPriceBlack} ${styles.blockBorder}`}>
                                    <img src={line} width="auto" height="auto" alt="line" />
                                    <p>Free</p>
                                    <span id="price-premium" className={styles.textIndex}>$12</span>
                                    <span className={styles.text}>mnt / per user</span>
                                    <button className={buttons.buttonSignUp} onClick={this.openSignUp}>Start Free
                                        Trial</button>
                                </div>
                                <PricingList pricingList={Premium} />
                            </div>
                        </div>
                        <div className={`${styles.block} ${styles.blockRight}`}>
                            <div className={styles.blockTitle}>Enterprise</div>
                            <div className={styles.blockPrice}>
                                <h6>Custom Price</h6>
                                <span className={styles.text}>for larger teams</span>
                                <button className={buttons.learnMore} onClick={this.openIntercom}>Contact Us</button>
                            </div>
                            <PricingList pricingList={Enterprise} />
                        </div>
                    </div>
                    <div className={styles.integrationIcons}>
                        {
                            imgs.map(img => <img src={img} width="auto" height="auto" alt="icon" />)
                        }
                    </div>
                    <h2>Frequently Asked Questions</h2>
                    <div className={styles.faq}>
                        <div className={styles.leftContent}>
                            <div>
                                <span className={styles.faqTitle}>How are users counted?</span>
                                <p>Only active, time tracking users are counted towards your plan limit. You are free to
                                    enable and disable users as you see fit.</p>
                            </div>
                            <div>
                                <span className={styles.faqTitle}>Do you have monthly and yearly billing options?</span>
                                <p>Yes! We offer either monthly or yearly billing options. The yearly billing option is always
                                    cheaper (usually ~25% discount). For example, the Team plan is $8 per active member per
                                    month in the monthly option, but $6 in the yearly option.</p>
                            </div>
                            <div>
                                <span className={styles.faqTitle}>Can I use DueFocus for free?</span>
                                <p>Absolutely! DueFocus is completely free to use indefinitely for 1-2 active users.</p>
                            </div>
                            <div>
                                <span className={styles.faqTitle}>How is the payment being processed?</span>
                                <p>We use Stripe to process your payment. It's the same payment provider used in products such
                                    as Twitter, Pinterest, and Lyft. We do not handle your credit card information directly.
                                </p>
                            </div>
                        </div>
                        <div className={styles.rightContent}>
                            <div>
                                <span className={styles.faqTitle}>Do you offer discounts?</span>
                                <p>We are building an innovative time tracker for You and would like to create it with You.
                                    Get up to 50% discount on release by interacting with us via Intercom or by building our
                                    <a target='_blank' rel="noopener noreferrer" href='https://duefocus.canny.io/'
                                        className={styles.roadMap}> Product Roadmap</a>.</p>
                            </div>
                            <div>
                                <span className={styles.faqTitle}>Can I change my plan later?</span>
                                <p>Yes, you can upgrade your plan at any time. You will be credited for the remainder of your
                                    current plan and charged for your new plan when you upgrade. However you cannot downgrade
                                    your plan.</p>
                            </div>
                            <div>
                                <span className={styles.faqTitle}>What should I do if I want to cancel my service?</span>
                                <p>Its quite simple. If you realize that DueFocus isn’t for you, just cancel subscription
                                    before your next billing period. This date is listed on your billing page. Unfortunately,
                                    we can’t refund you for partial use or the amount of time left in your subscription.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
